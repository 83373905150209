import React from "react"
import Layout from "../../components/Layout"
import { useForm, ValidationError } from "@formspree/react"

function Contact() {

  function ContactForm() {
    let successMsg;
    const [state, handleSubmit] = useForm("xrgrdzgd");
    if (state.succeeded) {
      successMsg = <p style={{color:"white", marginLeft:"25px"}}>Thanks for contacting us!</p>;
    }
    return (
      <form onSubmit={handleSubmit} className={"fully-form rel"}>
        <div className={"name-email flex-center-rw"}>
          <input
            id="name"
            type="text"
            name="name"
            placeholder="Your Name"
          />
          <input
            id="email"
            type="email"
            name="email"
            placeholder="Your Email"
          />
        </div>
          <input
            id="subject"
            type="text"
            name="subject"
            placeholder="Your Subject"
          />
        <ValidationError
          prefix="Email"
          field="email"
          errors={state.errors}
        />
        <textarea
          id="message"
          name="message"
          placeholder="Your Message"
        />
        <div className={"w-100 flex-center-rw"}>
          <button type="submit" disabled={state.submitting}>
            Submit
          </button>
          {successMsg}
        </div>
      </form>
    )
  }

  return (
    <Layout>
      <main className={"contact-us-image w-100 flex-center-rw"}>
        <h1>CONTACT US</h1>
      </main>
      <section className={"address w-100 flex-center-rw"}>
        <div>
          <h3>EMAIL</h3>
          <h5>contact@aykunna.com <br />
          </h5>
        </div>
        <div>
          <h3>PHONE</h3>
          <h5>
            +4792021815
          </h5>
        </div>
        <div>
          <h3>ADDRESS</h3>
          <h5>Hatleveien 5, 5041 Bergen, Norway <br />
          </h5>
        </div>
      </section>
      <section className={"full-contact-form w-100 flex-center-rw rel"}>
        <div className={"contact-form rel"}>
          <ContactForm />
        </div>
        <div className={"form-info rel"}>
          <h2>Send Us A Message</h2>
          <p>
            Feel free to reach out to us with any questions. We are very friendly and always open to discussing new projects, interesting ideas, student work or opportunities to be part of an event.
          </p>
        </div>
      </section>
    </Layout>
  )
}

export default Contact